import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, Injectable, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { debounceTime, map, merge, mergeMap, Observable, Subject, switchMap, takeUntil } from 'rxjs';
import { fuseAnimations } from '@fuse/animations';
import { FuseConfirmationService } from '@fuse/services/confirmation';
import { Device, DevicePagination } from 'app/modules/admin/devicelist/devicelist.types';
import { DeviceListService, MSGraphDataState } from 'app/modules/admin/devicelist/devicelist.service';
import { registerMgtComponents } from '@microsoft/mgt';
import { Html5QrcodeScanner } from "html5-qrcode";
import { FuseAlertType } from '@fuse/components/alert';
import { DeviceFieldMappings } from 'app/modules/admin/devicelist/devicelist.types';
import { DataStateChangeEvent, GridDataResult, PageChangeEvent } from '@progress/kendo-angular-grid';
import { Router } from '@angular/router';


@Injectable()
export class MatPaginatorIntlCustomLabel extends MatPaginatorIntl {

  getRangeLabel = (page: number, pageSize: number, length: number) => {
    if (length === 0 || pageSize === 0) {
      return '0 of ' + length;
    }

    length = Math.max(length, 0);
    const startIndex = page * pageSize;
    // If the start index exceeds the list length, do not try and fix the end index to the end.
    const endIndex = startIndex < length ?
      Math.min(startIndex + pageSize, length) : startIndex + pageSize;
    return startIndex + 1 + ' - ' + endIndex;
  };
}


@Component({
    selector       : 'device-list',
    templateUrl    : './devicegrid.component.html',
    styles         : [
        /* language=SCSS */
        `
            div.mat-mdc-form-field-infix {
                min-height: 32px !important;
            }

            .fuse-alert-container {
                padding: 8px !important;
            }

            .device-grid {
                grid-template-columns: 48px auto 40px;

                @screen sm {
                    grid-template-columns: 48px auto 112px 72px;
                }

                @screen md {
                    grid-template-columns: 200px 180px 100px 150px 160px 150px auto 60px;
                }

                @screen lg {
                    grid-template-columns: 200px 180px 100px 150px 160px 150px auto 60px;
                }
            }
            .device-image {
                display: inline-block;
                width: 24px;
                height: 24px;
                background-size: 24px 24px;
                background-position: center center;
                vertical-align: middle;
                line-height: 32px;
                margin-left: 5px;
            }

            .device-name {
                display: inline-block;
                vertical-align: middle;
                line-height: 32px;
                padding-left: 10px;
            }
            button, [type='button'], [type='reset'], [type='submit'] {
                -webkit-appearance: button;
                background-color: #3f51b5;
                background-image: none;
            }
        `
    ],
    encapsulation  : ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations     : fuseAnimations
})
export class DeviceGridComponent implements OnInit, AfterViewInit, OnDestroy
{
    @ViewChild(MatPaginator) private _paginator: MatPaginator;
    @ViewChild(MatSort) private _sort: MatSort;


    deviceToImageMap = {
        "Microsoft Corporation": "assets/images/microsoft.png",
        "Dell Inc.": "assets/images/dell.png",
        "ASUSTeK COMPUTER INC.": "assets/images/hp.png",
        "VMware, Inc.": "assets/images/vmware.png",
    };

    DeviceFieldMappings: DeviceFieldMappings = new DeviceFieldMappings();

    
    // TODO: obtain list of person from API

    public griDataView: Observable<GridDataResult>;
    public state: MSGraphDataState = { skip: 0, take: 50, search: '', persona: '', sort: [ { field: "deviceName", dir: "asc"} ] };

    devices$: Observable<Device[]>;
    personaList$: Observable<string[]>;

    flashMessage: 'success' | 'error' | null = null;
    isLoading: boolean = false;

    pagination: DevicePagination = {
        size: 10, length: 10, page: 1, startIndex: 1, lastPage: 1,
        endIndex: 1
    };
    searchInputControl: UntypedFormControl = new UntypedFormControl();
    personaControl: UntypedFormControl = new UntypedFormControl();

    private _unsubscribeAll: Subject<any> = new Subject<any>();

    /**
     * Constructor
     */
    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _fuseConfirmationService: FuseConfirmationService,
        private _formBuilder: UntypedFormBuilder,
        private _devicelistService: DeviceListService,
        private router: Router
    )
    {
        this.griDataView = _devicelistService;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
        this.devices$ = this._devicelistService.devices$;

        this._devicelistService.queryDeviceData(this.state);
           
        // Subscribe to search input field value changes
        merge(this.searchInputControl.valueChanges, this.personaControl.valueChanges)
            .pipe(
                takeUntil(this._unsubscribeAll),
                debounceTime(500),
                switchMap(() => {
                    this.state.search = this.searchInputControl.value;
                    this.state.persona = this.personaControl.value;
                    // this.isLoading = true;
                    //return this._devicelistService.getDevices(this.pagination.size, this._sort.active, this._sort.direction, this.searchInputControl.value, this.personaControl.value);

                    return this._devicelistService.queryDeviceData(this.state);
                }),
            )
            .subscribe();

        this.personaList$ = this._devicelistService.queryPersonas();

        /*
        this.personaControl.valueChanges
            .pipe(
                takeUntil(this._unsubscribeAll),
                switchMap((persona) => {
                    this.isLoading = true;
                    return this._devicelistService.getDevices(this.pagination.size, this._sort.active, this._sort.direction, this.searchInputControl.value, persona);
                }),
                map(() => {
                    this.isLoading = false;
                })
            )
            .subscribe();    
        */
       
        //this._devicelistService.getDevices()
        //    .pipe(takeUntil(this._unsubscribeAll))
        //    .subscribe();
    }

    /**
     * After view init
     */
    ngAfterViewInit(): void
    {
        /*
        if ( this._sort && this._paginator )
        {
            // Set the initial sort
            this._sort.sort({
                id          : 'deviceName',
                start       : 'asc',
                disableClear: true
            });

            // Mark for check
            this._changeDetectorRef.markForCheck();

            // If the user changes the sort order...
            this._sort.sortChange
                .pipe(takeUntil(this._unsubscribeAll))
                .subscribe(() => {
                    // Reset back to the first page
                    this._paginator.pageIndex = 0;
                });


            // Get devices if sort or page changes
            this._sort.sortChange.pipe(
                switchMap(() => {
                    this.isLoading = true;
                    return this._devicelistService.getDevices(this._paginator.pageSize, this._sort.active, this._sort.direction, this.searchInputControl.value, this.personaControl.value);
                }),
                map(() => {
                    this.isLoading = false;
                })
            ).subscribe();

            this._paginator.page.pipe(
                switchMap((request) => {
                    this.isLoading = true;
                    // if(this._paginator.pageSize)
                    if (this._paginator.pageSize == this._devicelistService.currentSize && this._paginator.pageIndex > this._devicelistService.currentPage) {
                        return this._devicelistService.getNextDevices(this._paginator.pageSize, this._sort.active, this._sort.direction, this.searchInputControl.value, this.personaControl.value);
                    } else if (this._paginator.pageSize == this._devicelistService.currentSize && this._paginator.pageIndex < this._devicelistService.currentPage) {
                        return this._devicelistService.getPrevDevices(this._paginator.pageSize, this._sort.active, this._sort.direction, this.searchInputControl.value, this.personaControl.value);
                    } else  {
                        return this._devicelistService.getDevices(this._paginator.pageSize, this._sort.active, this._sort.direction, this.searchInputControl.value, this.personaControl.value);
                    }

                }),
                map(() => {
                    this.isLoading = false;
                })
            ).subscribe();
        }
        else
        {
            // alert('Error');
        }
        */
    }

    get isGridLoading(): boolean
    {
        return this._devicelistService.isLoading;
    }

    onPersonaSelectionChange($event)
    {

    } 

    public onUserExp(deviceId: string): void
    {
        this.router.navigate(['/user_experience', deviceId])
        // this._devicelistService.queryUserExperience(device.id).subscribe();
    }

    public onPageChange(eventData: PageChangeEvent): void 
    {
        this.state.skip = eventData.skip;
        this._devicelistService.queryDeviceData(this.state);
    }

    public onDataStateChange(state: DataStateChangeEvent): void {
        this.state.sort = state.sort;
        // this.state.skip = 0;
        this._devicelistService.queryDeviceData(this.state);
      }


    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------


    public getDeviceImage(manufacturer: string): string
    {
        if(this.deviceToImageMap[manufacturer])
        {
            return `url('${this.deviceToImageMap[manufacturer]}')`;
        } 
        else
        {
            return "url('assets/images/unknown-device.png')";
        }
    }
 
    /**
     * Track by function for ngFor loops
     *
     * @param index
     * @param item
     */
    trackByFn(index: number, item: any): any
    {
        return item.id || index;
    }
}
