import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, ReplaySubject, tap } from 'rxjs';
import { UiSettings } from 'app/core/config/settings.type';

@Injectable({
    providedIn: 'root'
})
export class SettingsService
{
    private _settings: UiSettings;

    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient)
    {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for settings
     */
    get settings(): UiSettings
    {
        return this._settings;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get all settings data
     */
    get(): Observable<UiSettings>
    {
        return this._httpClient.get<UiSettings>('./assets/ui-settings.json').pipe(
            tap((settings) => {
                this._settings = settings;
            })
        );
    }
}
