<div class="sm:absolute sm:inset-0 flex flex-col flex-auto min-w-0 sm:overflow-hidden bg-card dark:bg-transparent">

    <!-- Header -->
    <div class="relative flex flex-col sm:flex-row flex-0 sm:items-center sm:justify-between py-4 px-4 md:px-8 border-b">
        <!-- Title -->
        <div class="text-4xl font-extrabold tracking-tight">All devices</div>
        <!-- Actions -->
        <div class="flex shrink-0 items-center mt-6 sm:mt-0 sm:ml-4">
            <!-- Search -->
            <mat-form-field
                class="fuse-mat-dense fuse-mat-rounded min-w-64 w-12" style="width: 350px !important"
                [subscriptSizing]="'dynamic'">
                <mat-icon
                    class="icon-size-5"
                    matPrefix
                    [svgIcon]="'heroicons_solid:search'"></mat-icon>
                <input
                    matInput
                    [formControl]="searchInputControl"
                    [autocomplete]="'off'"
                    [placeholder]="'Search devices'">
            </mat-form-field>
            <mat-form-field appearance="fill" class="responsive-width ml-2" style="width: 350px !important">
                <mat-label>Persona</mat-label>
                <mat-select [formControl]="personaControl" multiple (selectionChange)="onPersonaSelectionChange($event);">
                    <mat-option *ngFor="let persona of personaList$ | async" [value]="persona">{{persona}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>

    <!-- Main -->
    <div class="flex flex-auto overflow-hidden">

        <!-- Kendo grid -->
        <kendo-grid
            [data]="griDataView | async"
            [loading]="isGridLoading"
            [pageSize]="state.take"
            [skip]="state.skip"
            scrollable="virtual"
            [rowHeight]="36"
            [reorderable]="true"
            [resizable]="true"
            [sort]="state.sort"
            [sortable]="true"
            kendoGridSelectBy="id"
            [columnMenu]="{ filter: false }"
            (pageChange)="onPageChange($event)"
            (dataStateChange)="onDataStateChange($event)"
        >
            <kendo-grid-checkbox-column
                [width]="45"
                [headerClass]="{ 'text-center': true }"
                [class]="{ 'text-center': true }"
                [resizable]="false"
                [columnMenu]="false"
                [showSelectAll]="true"
            ></kendo-grid-checkbox-column>
            <kendo-grid-column field="deviceName" title="Device Name" [width]="300" [sortable]="true">
                <ng-template kendoGridCellTemplate let-dataItem>
                    <div
                    class="device-image"
                    [ngStyle]="{ 'background-image': getDeviceImage(dataItem.manufacturer) }"
                    ></div>
                    <div class="device-name">{{ dataItem.deviceName }}</div>
                </ng-template>        
            </kendo-grid-column>
            <kendo-grid-column field="serialNumber" title="Serial Number" [width]="350" [sortable]="true"></kendo-grid-column>
            <kendo-grid-column field="complianceState" title="Compliance State" [width]="200">
                <ng-template kendoGridCellTemplate let-dataItem>
                    <div class="truncate">
                        {{ DeviceFieldMappings.ComplianceState[dataItem.complianceState ]}}
                    </div>        
                </ng-template>        
            </kendo-grid-column>
            <kendo-grid-column field="deviceEnrollmentType" title="Enrollment Type" [width]="200">
                <ng-template kendoGridCellTemplate let-dataItem>
                    <div class="truncate">
                        {{ DeviceFieldMappings.EnrollmentType[dataItem.deviceEnrollmentType]}}
                    </div>
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column field="userPrincipalName" title="Primary User UPN" [sortable]="true"></kendo-grid-column>
            <kendo-grid-command-column title="Actions" [width]="200">
                <ng-template kendoGridCellTemplate let-dataItem>
                    <div class="action-buttons">
                        <button
                        class="min-w-10 h-7 px-2 leading-6"
                        style="margin-left: 5px;"
                        mat-stroked-button
                        [matMenuTriggerFor]="summaryMenu">
                        <mat-icon class="icon-size-5" [svgIcon]="'mat_solid:arrow_drop_down'"></mat-icon>
                    </button>
                    <mat-menu #summaryMenu="matMenu">
                        <button mat-menu-item (click)="onUserExp(dataItem.id)">User Experience</button>
                    </mat-menu>
                    </div>
                </ng-template>
            </kendo-grid-command-column>            
        </kendo-grid>

    </div>

</div>
