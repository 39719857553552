import { Component, OnDestroy, OnInit } from '@angular/core';
import { environment } from 'environments/environment'
import { registerMgtComponents, Providers, ProxyProvider } from '@microsoft/mgt';
import { Subject } from 'rxjs';

@Component({
    selector   : 'app-root',
    templateUrl: './app.component.html',
    styleUrls  : ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy
{

    private readonly _destroying$ = new Subject<void>();

    /**
     * Constructor
     */
    constructor()
    {
    }

    public ngOnInit() {
        //this.configureMgt();
    }

    private configureMgt() {
        Providers.globalProvider = new ProxyProvider(environment.apiConfig.baseUrl + "/api/msgraph");
    
        registerMgtComponents();
    }

    ngOnDestroy(): void {
        this._destroying$.next(undefined);
        this._destroying$.complete();
    }
    
    
    
}
