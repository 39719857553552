export const environment = {
	name: "staging",
	production: true,
	msalConfig: {
		auth: {
		  clientId: '9a0f14be-47e5-484b-a531-481efdf7c363',
		  authority: 'https://login.microsoftonline.com/2de50486-5d01-43cd-a83a-6ceb85092334'
	}
	},
	graphConfig: {
		  scopes: ['user.read'],
		  uri: 'https://graph.microsoft-ppe.com/v1.0/me'
	},
	apiConfig: {
		scopes: ['api://desktopattendant/Device.Register'],
		baseUrl: 'https://func-desktopattendant-ccm-stg-01.azurewebsites.net',
		uri: 'api://desktopattendant'
	}
  };
