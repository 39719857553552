import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { registerMgtComponents, Providers, ProxyProvider } from '@microsoft/mgt';
import { MsalService, MsalBroadcastService, MSAL_GUARD_CONFIG, MsalGuardConfiguration } from '@azure/msal-angular';
import { catchError, filter, map, Observable, of, Subscription, switchMap, throwError } from 'rxjs';
import { AuthUtils } from 'app/core/auth/auth.utils';
import { UserService } from 'app/core/user/user.service';
import {AccountInfo, AuthenticationResult, EndSessionPopupRequest, EventMessage, EventType, IPublicClientApplication, InteractionStatus, PopupRequest, RedirectRequest} from "@azure/msal-browser";
import {MsalGuardAuthRequest} from "@azure/msal-angular/msal.guard.config";
import {RouterStateSnapshot} from "@angular/router";
import { User } from '../user/user.types';
import { environment } from 'environments/environment';

@Injectable()
export class AuthService
{


     private _authenticated: boolean = false;

    /**
     * Constructor
     */
    constructor(
        private _httpClient: HttpClient,
        private _userService: UserService,
        private _msalService: MsalService,
        private _msalBroadcastService: MsalBroadcastService
    ) {
        this._msalBroadcastService.msalSubject$
            .pipe(
                filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS || msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS),
            )
            .subscribe((result: EventMessage) => {
                console.log(result);
                const payload = result.payload as AuthenticationResult;
                if (result.eventType === EventType.LOGIN_SUCCESS) {
                    this.setActiveAccount(payload.account);
                } else {
                    this.onAuthenticated(payload.accessToken);
                }
            });

        /*
        this._msalBroadcastService.inProgress$
            .pipe(
                filter((status: InteractionStatus) => status === InteractionStatus.None)
            )
            .subscribe(() => {
                // TODO: logon procedure just finished // this.setLoginDisplay();
                const account = this._msalService.instance.getActiveAccount();
                this._authenticated = true;
                // TODO: Refactor User class to store MSAL user attributes only
                this._userService.user = {...account, id: account.username, email: account.username } as User;
                // return this._userService.user;
            })
        */
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Setter & getter for access token
     */
    set accessToken(token: string)
    {
        localStorage.setItem('accessToken', token);
    }

    get accessToken(): string
    {
        return localStorage.getItem('accessToken') ?? '';
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------


    loginRedirect(request?: RedirectRequest) {
        this._msalService.loginRedirect(request);
    }
    
    getAllAccounts(): AccountInfo[]  {
        return this._msalService.instance.getAllAccounts();
    }

    getActiveAccount(): AccountInfo {
        return this._msalService.instance.getActiveAccount();
    }

    setActiveAccount(account: AccountInfo) {
        this._msalService.instance.setActiveAccount(account);
    }

    logoutRedirect() {
        this._msalService.logoutRedirect();
    }

    logoutPopup(logoutRequest?: EndSessionPopupRequest) {
        this._msalService.logoutPopup(logoutRequest);
    }

    /**
     * Sign in
     *
     * @param credentials
     */
    signIn(credentials: { email: string; password: string }): Observable<any>
    {
        // Throw error, if the user is already logged in
        if ( this._authenticated )
        {
            return throwError('User is already logged in.');
        }

        return this._httpClient.post('api/auth/sign-in', credentials).pipe(
            switchMap((response: any) => {

                // Store the access token in the local storage
                this.accessToken = response.accessToken;

                // Set the authenticated flag to true
                this._authenticated = true;

                // Store the user on the user service
                this._userService.user = response.user;

                // Return a new observable with the response
                return of(response);
            })
        );
    }

    signInMsal(msalGuardAuthRequest: MsalGuardAuthRequest | ((authService: MsalService, state: RouterStateSnapshot) => MsalGuardAuthRequest)):  Observable<User>
    {
        if (msalGuardAuthRequest){

            return this._msalService.loginPopup(!!msalGuardAuthRequest ? ({...msalGuardAuthRequest} as PopupRequest) : null)
                .pipe(map((response: AuthenticationResult) => {
                    this._msalService.instance.setActiveAccount(response.account);
                    this.accessToken = response.accessToken;
                    this._authenticated = true;
                    // TODO: Refactor User class to store MSAL user attributes only
                    this._userService.user = {...response.account, id: response.account.username, email: response.account.username } as User;
                    // this.onAuthenticated(response.accessToken);
                    return this._userService.user;
                }));
        }

        return of({ id: null, name: null, email: null });
    }

    /**
     * Sign in using the access token
     */
    signInUsingToken(): Observable<any>
    {
        this._authenticated = true;
        const user = this._msalService.instance.getActiveAccount();
        // TODO: Refactor User class to store MSAL user attributes only
        this._userService.user = {...user, id: user.username, email: user.username } as User;

        return of(true);
    }

    /**
     * Sign out
     */
    signOut(): Observable<any>
    {
        // Remove the access token from the local storage
        localStorage.removeItem('accessToken');

        // Set the authenticated flag to false
        this._authenticated = false;

        // Return the observable
        return of(true);
    }

    /**
     * Sign up
     *
     * @param user
     */
    signUp(user: { name: string; email: string; password: string; company: string }): Observable<any>
    {
        return this._httpClient.post('api/auth/sign-up', user);
    }

    /**
     * Unlock session
     *
     * @param credentials
     */
    unlockSession(credentials: { email: string; password: string }): Observable<any>
    {
        return this._httpClient.post('api/auth/unlock-session', credentials);
    }

    /**
     * Check the authentication status
     */
    check(): Observable<boolean>
    {
        // Check if the user is logged in
        if ( this._authenticated )
        {
            return of(true);
        }

        // Check the access token availability
        if ( !this.accessToken )
        {
            return of(false);
        }

        // Check the access token expire date
        if ( AuthUtils.isTokenExpired(this.accessToken) )
        {
            return of(false);
        }

        // If the access token exists and it didn't expire, sign in using it
        return this.signInUsingToken();
    }

    onAuthenticated(accessToken: string): void
    {
        Providers.globalProvider = new ProxyProvider(environment.apiConfig.baseUrl + "/api/msgraph", 
            async () => {
                return {
                    "Authorization": "Bearer " + accessToken,
                };
            }
        );
    }
}
