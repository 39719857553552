/* tslint:disable:max-line-length */
import { FuseNavigationItem } from '@fuse/components/navigation';

export const defaultNavigation: FuseNavigationItem[] = [
    {
        id   : 'devices',
        title: 'All Devices',
        type : 'basic',
        icon : 'heroicons_outline:server',
        link : '/all_devices'
    },
    {
        id   : 'autopilot',
        title: 'Autopilot Devices',
        type : 'basic',
        icon : 'heroicons_outline:server',
        link : '/autopilot_devices'
    },
    {
        id   : 'users',
        title: 'Users',
        type : 'basic',
        icon : 'heroicons_outline:users',
        link : '/users'
    },
    {
        id   : 'newdevice',
        title: 'Register New Device',
        type : 'basic',
        icon : 'heroicons_outline:plus-circle',
        link : '/new_device'
    }
];
/*
export const compactNavigation: FuseNavigationItem[] = [
    {
        id   : 'example',
        title: 'Example',
        type : 'basic',
        icon : 'heroicons_outline:chart-pie',
        link : '/example'
    }
];
export const futuristicNavigation: FuseNavigationItem[] = [
    {
        id   : 'example',
        title: 'Example',
        type : 'basic',
        icon : 'heroicons_outline:chart-pie',
        link : '/example'
    }
];
export const horizontalNavigation: FuseNavigationItem[] = [
    {
        id   : 'example',
        title: 'Example',
        type : 'basic',
        icon : 'heroicons_outline:chart-pie',
        link : '/example'
    }
];
*/
